import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteNamespaces } from 'services/api/namespaceService';
import { formatDateTime } from 'utilities/formatDateTime';
import { toast } from 'react-toastify';
import { FaPlus } from 'react-icons/fa';

const NamespacesListInTenant = ({
  tenantId,
  organizationId,
  namespaces,
  onAddNamespace,
}) => {
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const { slug, tenName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (namespaces && namespaces.length > 0) {
      setSelectedNamespace(namespaces[0].identity);
    }
  }, [namespaces]);

  const handleDelete = async namespaceIdentity => {
    try {
      await deleteNamespaces(organizationId, tenantId, namespaceIdentity);
      toast.success('Namespace deleted successfully.', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (err) {
      toast.error('Error deleting namespace.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleClickNamespace = namespaceName => {
    navigate(`/org/namespaces/${slug}/${tenName}/${namespaceName}`);
  };

  const selected = namespaces?.find(ns => ns.identity === selectedNamespace);

  return (
    <div className="max-w-8xl mx-auto mt-2">
      {/* Namespace Selector */}
      <div className="mb-4">
        <label
          htmlFor="namespace-select"
          className="block text-sm text-gray-700"
        >
          Select Namespace:
        </label>
        <div className="flex justify-between gap-3">
          <select
            id="namespace-select"
            value={selectedNamespace}
            onChange={e => setSelectedNamespace(e.target.value)}
            className="mt-2 block w-[140px] p-1 text-xs border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:w-[620px] dark:bg-navy-700 dark:border-gray-700 dark:text-white dark:focus:ring-navy-600 dark:focus:border-navy-600"
            disabled={!namespaces || namespaces.length === 0}
          >
            {namespaces && namespaces.length > 0 ? (
              namespaces.map(namespace => (
                <option key={namespace.identity} value={namespace.identity}>
                  {namespace.name}
                </option>
              ))
            ) : (
              <option>No namespaces available</option>
            )}
          </select>
          <button
            className="flex flex-row mt-2 items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md dark:bg-navy-600 dark:hover:bg-navy-700"
            onClick={onAddNamespace}
          >
            <FaPlus className="mr-2" />
            Add Namespace
          </button>
        </div>
      </div>

      {/* Namespace Table */}
      {selected && (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-400 dark:bg-navy-700">
              <tr>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Name
                </th>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Description
                </th>
                <th className="px-4 py-1 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Created At
                </th>
                <th className="relative px-4 py-1">
                  <span className="text-left text-xs font-medium text-white uppercase tracking-wider">
                    Action
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
              <tr className="hover:bg-gray-50 transition dark:hover:bg-navy-600">
                <td
                  className="px-6 py-2 text-sm font-medium text-blue-600 cursor-pointer whitespace-nowrap lg:text-sm dark:text-blue-300"
                  onClick={() => handleClickNamespace(selected.name)}
                >
                  {selected.name}
                </td>
                <td className="px-6 py-2 text-sm text-gray-900 whitespace-nowrap lg:text-md dark:text-white">
                  {selected.description}
                </td>
                <td className="px-6 py-2 text-sm text-gray-900 whitespace-nowrap lg:text-md dark:text-white">
                  {formatDateTime(selected.createdAt)}
                </td>
                <td className="px-6 py-2 text-center text-sm font-medium">
                  <button
                    onClick={() => handleDelete(selected.identity)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NamespacesListInTenant;
