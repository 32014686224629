import Keycloak from 'keycloak-js';


// Define Keycloak configuration object
const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

const keycloak = new Keycloak(keycloakConfig);




const initKeycloak = async (onAuthenticatedCallback) => {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false, 
    });
  

    if (authenticated) {
      console.log("User authenticated");
    }
    
    onAuthenticatedCallback(authenticated);
  } catch (error) {
    console.error("Keycloak initialization failed:", error);
    onAuthenticatedCallback(false);
  }
};

const KeycloakService = {
  keycloak,
  initKeycloak,
  doLogin: () => keycloak.login(),
  doLogout: () => keycloak.logout(),
  isLoggedIn: () => !!keycloak.token,
  getToken: () => keycloak.token,
  updateToken: async (minValidity = 5) => {
    try {
      const refreshed = await keycloak.updateToken(minValidity);
      if (refreshed) {
        console.log('Token refreshed');
      } else {
        console.log('Token is still valid');
      }
      return keycloak.token;
    } catch (error) {
      console.error('Failed to refresh token', error);
      throw error;
    }
  },
  getUsername: () => keycloak.tokenParsed?.preferred_username,
  hasRole: (roles) => roles.some(role => keycloak.hasRealmRole(role)),
  async getUserProfile() {
    try {
      const userProfile = await keycloak.loadUserProfile();
      return {
        githubAvatarUrl: userProfile.attributes?.github_avatar_url,
        googleProfilePhotoUrl: userProfile.attributes?.picture, 
        microsoftProfilePhotoUrl: userProfile.attributes?.microsoft_avatar_url
      };
    } catch (error) {
      console.error("Failed to load user profile:", error);
      return {
        githubAvatarUrl: null,
        googleProfilePhotoUrl: null,
        microsoftProfilePhotoUrl: null
      };
    }
  },
  async updateUserProfile(userId, githubAvatarUrl, googleProfilePhotoUrl, microsoftProfilePhotoUrl) {
    const userProfile = await keycloak.loadUserProfile();

    const attributes = userProfile.attributes || {};
    
    if (githubAvatarUrl) {
      attributes.github_avatar_url = githubAvatarUrl;
    }
    if (googleProfilePhotoUrl) {
      attributes.picture = googleProfilePhotoUrl; 
    }
    if (microsoftProfilePhotoUrl) {
      attributes.microsoft_avatar_url = microsoftProfilePhotoUrl; 
    }
    
    await keycloak.updateUserProfile({ attributes });
  },
  
};

export default KeycloakService;
