import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBackward, FaPlus } from 'react-icons/fa';
import { formatDateTime } from 'utilities/formatDateTime';
import CloudIcon from 'utilities/CloudIcon';
import Loading from 'views/loading/Loading';
import TenantStatus from 'utilities/TenantStatus';
import KubeTenantView from './KubeTenantView';
import CreateTenant from './CreateTenant';

const TenantsView = ({
  organizationId,
  tenants,
  error,
  loading,
  onSelectTenant,
  selectedTenant,
}) => {
  const [showCreateTenant, setShowCreateTenant] = useState(false);
  const [tenantsList, setTenantsList] = useState(tenants);
  const navigate = useNavigate();

  const handleTenantClick = tenant => {
    onSelectTenant(tenant);
    navigate(`/org/tenants/${organizationId}/${tenant.name}`);
  };

  const handleBackToList = () => {
    onSelectTenant(null);
    navigate('/org/tenants/:slug/:tenName');
  };

  const handleCreateTenant = newTenant => {
    setShowCreateTenant(false);
    setTenantsList([...tenantsList, newTenant]);
  };

  const refreshTenants = deletedTenantId => {
    setTenantsList(prevList =>
      prevList.filter(tenant => tenant.identity !== deletedTenantId),
    );
  };

  return (
    <div className="max-w-8xl mx-auto  mt-12">
      {error && <p className="text-red-600 text-center mb-4">{error}</p>}

      {loading ? (
        <Loading />
      ) : (
        <>
          {selectedTenant ? (
            <div>
              <button
                onClick={handleBackToList}
                className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md lg:mb-4 dark:bg-navy-600 dark:hover:bg-navy-700"
              >
                <FaBackward className="mr-2" />
                Back to Tenants List
              </button>
              <KubeTenantView
                organizationId={selectedTenant.organizationId}
                tenantId={selectedTenant.identity}
                refreshTenants={refreshTenants}
              />
            </div>
          ) : (
            <>
              <div className="flex justify-between mb-4">
                <h1 className="text-sm font-bold text-gray-800 lg:text-lg dark:text-white">
                  Tenant Spaces
                </h1>
                <div>
                  <button
                    className="flex flex-row items-center bg-customBlue hover:bg-gray-900 hover:shadow-lg text-white text-xs font-semibold py-2 px-4 rounded-md mb-3 dark:bg-navy-600 dark:hover:bg-navy-700"
                    onClick={() => setShowCreateTenant(true)}
                  >
                    <FaPlus className="mr-2" />
                    Add Tenant Space
                  </button>
                </div>
              </div>

              <div>
                {tenantsList.length > 0 ? (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-400 dark:bg-navy-700">
                        <tr>
                          <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Cloud Service
                          </th>
                          <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Cloud Region
                          </th>
                          <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Description
                          </th>
                          <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Created At
                          </th>
                          <th className="px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:bg-navy-700 dark:divide-gray-700">
                        {tenantsList.map(tenant => (
                          <tr
                            key={tenant.identity}
                            className="hover:bg-gray-50 transition dark:hover:bg-navy-600"
                          >
                            <td className="px-6 py-2 mr-12 whitespace-nowrap text-sm text-gray-900 flex items-center justify-center">
                              {tenant.cloud?.cloudProviderType && (
                                <Link
                                  to="#"
                                  onClick={() => handleTenantClick(tenant)}
                                >
                                  <CloudIcon
                                    cloudProviderType={
                                      tenant.cloud.cloudProviderType
                                    }
                                    className="w-7 h-7 mr-6"
                                  />
                                </Link>
                              )}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                              {tenant.cloudRegion?.name || 'N/A'}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium dark:text-white">
                              <Link
                                to="#"
                                onClick={() => handleTenantClick(tenant)}
                                className="text-blue-500 hover:text-blue-700 dark:text-blue-400"
                              >
                                {tenant.name}
                              </Link>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                              {tenant.description || 'N/A'}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                              {formatDateTime(tenant.createdAt)}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <TenantStatus status={tenant.status} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </>
      )}

      {/* Modal for CreateTenant */}
      {showCreateTenant && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-lg">
            <CreateTenant
              organizationId={organizationId}
              onCreate={handleCreateTenant}
              onCancel={() => setShowCreateTenant(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TenantsView;
