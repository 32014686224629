import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NotFound from 'components/notfound/NotFound';
import RtlLayout from 'layouts/rtl';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import OrgLayout from 'layouts/org';
import MainLayout from 'layouts/main';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<MainLayout />} />
        <Route path="settings/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="org/*" element={<OrgLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route path="/not-found" element={<NotFound />} />
      </Routes>
      <ToastContainer autoClose={3000} hideProgressBar />
    </>
  );
};

export default App;
